// Define arrays for month names and days
const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


export function generateDate(dateString) {
  // Create a Date object
  const date = new Date(dateString);
  // Extract day, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Format the result
  return `${month} ${day}, ${year}`;
}