import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space, Popconfirm, message, Tooltip, Tag, Switch, DatePicker } from "antd";
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import BlogPage, { EditBlogModal } from "../../pages/Blog/PostBlog";
import { createClient } from "../../utils/supabase/client";

const { Search } = Input;

const BlogTable = () => {
  const supabase = createClient();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [originalData, setOriginalData] = useState([]); // Store full blog list
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setLoading] = useState(false);



  // get all the blogs
  async function getblogs() {
    setLoading(true);
    try {
      const response = await fetch("/api/blogs/getblogs", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        const result = await response.json();

        const blogData = result.data.map((blog) => ({
          ...blog,
          key: blog.uuid,
          title: blog.name,
          isActive: blog.isActive,
          author: blog.writer_name,
          date: moment(blog.created_at).format("YYYY-MM-DD"),
          categories: blog?.blog_categories?.map(item => {
            return { label: item.categories.name, value: item.category_uuid }
          })
        }));
        setOriginalData(blogData);
        setFilteredData(blogData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getblogs();
  }, []);

  // Toggle active status
  const handleToggleActive = async (blogId, currentStatus) => {
    // Optimistically update the UI
    const newStatus = !currentStatus;
    setFilteredData((prevData) =>
      prevData.map((item) =>
        item.key === blogId ? { ...item, isActive: newStatus } : item
      )
    );

    try {
      // Send API request to update in Supabase
      const response = await fetch("/api/blogs/toggleActive", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ blogId, isActive: newStatus }),
      });

      if (!response.ok) throw new Error("Failed to update status");

      message.success("Status updated successfully");
    } catch (error) {
      console.error(error);
      message.error("Failed to update status");

      // Revert to previous state on failure
      setFilteredData((prevData) =>
        prevData.map((item) =>
          item.key === blogId ? { ...item, isActive: currentStatus } : item
        )
      );
    }
  };



  // Handle Search
  const onSearch = (value) => {
    setSearchText(value);
    if (!value) {
      setFilteredData(originalData);
      return;
    }

    const lowerCaseValue = value.toLowerCase();
    const filteredBlogs = originalData.filter((blog) =>
      ["title", "description", "author"].some(
        (key) => blog[key]?.toLowerCase().includes(lowerCaseValue)
      )
    );

    setFilteredData(filteredBlogs);
  };

  // Delete function
  const handleDelete = async (blogId) => {
    const prevData = [...filteredData]; // Store previous state for rollback

    // Optimistic UI: Remove the blog immediately
    setFilteredData(filteredData.filter((item) => item.key !== blogId));

    try {
      const response = await fetch("/api/blogs/deleteblog", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ blogId }),
      });

      if (!response.ok) throw new Error("Failed to delete blog");

      message.success("Blog post deleted successfully");
    } catch (error) {
      console.error(error);
      message.error("Failed to delete blog");

      // Revert state if deletion fails
      setFilteredData(prevData);
    }
  };

  // Handle Date Filter
  const handleFilter = (date) => {
    if (!date) {
      setFilteredData(originalData); // Reset all blogs when cleared
      setSelectedDate(null);
      return;
    }

    setSelectedDate(date);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const filteredBlogs = originalData.filter((blog) =>
      moment(blog.date).format("YYYY-MM-DD") === formattedDate
    );

    setFilteredData(filteredBlogs);
  };


  // when edit is successful 
  const handleUpdate = () => {
    getblogs(); // Refresh the blog list
  };

  const handleClearFilter = () => {
    getblogs()
    setSelectedDate(null);
    setSearchText(""); // Clear search value
  };

  // Table columns
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text) => (
        <img
          src={text}
          alt="blog photos"
          style={{
            width: 50,
            height: 50,
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />
      ),
      responsive: ["sm"],
    },
    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: text }} />}>
          <div dangerouslySetInnerHTML={{ __html: text.length > 100 ? `${text.substring(0, 100)}...` : text }} />
        </Tooltip>
      ),
    },
    {
      title: "Categories",
      key: "categories",
      dataIndex: "categories",
      render: (categories) => (
        <span>
          {categories.map((category) => {
            let color = category.label.length > 5 ? "geekblue" : "green";
            if (category === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={category.label}>
                {category.label.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      ),
    },
    { title: "Author", dataIndex: "author", key: "author" },
    { title: "Date", dataIndex: "date", key: "date" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Switch
            checked={record.isActive}
            onChange={() => handleToggleActive(record.key, record.isActive)}
            checkedChildren="Active"
            unCheckedChildren="Pending"
            style={{
              width: 70,
              backgroundColor: record.isActive ? "#52c41a" : "#ff4d4f",
            }}
          />
          <EditBlogModal blog={record} onUpdate={handleUpdate}>
            <Button type="link" icon={<EditOutlined />} />
          </EditBlogModal>
          <Popconfirm
            title="Are you sure to delete this blog?"
            onConfirm={() => handleDelete(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6 bg-white rounded">
      <h2 className="text-2xl font-bold text-center mb-6">Blog Data</h2>

      {/* Search and Filter Section */}
      <div className="block md:flex justify-between items-center mb-4">
        <div className="w-full md:w-[40%] flex gap-2">
          {/* <Button type="primary" className="py-[18px] w-full">Add New Blog</Button> */}

          <BlogPage onUpdate={handleUpdate} />

          <Button
            size="large"
            style={{ width: "126px" }}
            onClick={handleClearFilter}
          >
            Clear Filter
          </Button>

          <DatePicker
            size="large"
            value={selectedDate ? moment(selectedDate) : null}
            onChange={handleFilter}
            allowClear
            className="w-full md:w-auto"
          />
        </div>
        <div className="w-full md:w-[40%] mt-2 md:mt-0">
          <Search
            placeholder="Search by Title, Description, Author"
            allowClear
            size="large"
            value={searchText}
            onSearch={onSearch}
            onChange={(e) => onSearch(e.target.value)}
            style={{ width: "100%" }}
            enterButton="Search"
          />
        </div>
      </div>

      {/* Blog Table */}
      <Table
        dataSource={filteredData}
        columns={columns}
        pagination={{ pageSize: 5 }}
        bordered
        rowKey="key"
        className="custom-table"
        loading={isLoading}
      />
    </div>
  );
};

export default BlogTable;
