import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Logo from "../../assets/logo.png";
import Drive from "../../assets/drive/07.png";
import { NavLink } from "react-router-dom";

export default function PricingService() {
  const setSignInAndSignUpButtonStateToLocalStorage = (type) => {
    if (type === "signup") {
      localStorage.setItem("buttonState", "signup");
    } else if (type === "signin") {
      localStorage.setItem("buttonState", "signin");
    }
  };

  return (
    <>
      <Header />

      <section
        id="iq-home"
        className="iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax"
        data-jarallax='{"speed": 0.6}'
        style={{ background: "url(images/bg/01.jpg)" }}
      >

        <div className="container-fluid">
          <div className="banner-text">
            <div className="row justify-content-between">
              <div className="col-lg-6 align-self-center">

                <h1 className="text-uppercase iq-font-white iq-tw-3 text-[24px]!">
                  swiftlead offers a free trial as well as industry leading pricing
                  <span className="italic">
                    {" "}
                    —ALL IN ONE POWERFUL PLATFORM.
                  </span>
                </h1>

                <p className="iq-font-white iq-pt-18 iq-mb-40">
                  Swiftlead delivers job data and automates prospecting, helping you connect with employers or
                  clients efficiently and scale your outreach.
                </p>


              </div>
              <div className="col-lg-6">

              </div>
            </div>
          </div>
        </div>
        <div className="banner-objects">
          <span
            className="banner-objects-01"
            data-bottom="transform:translatey(50px)"
            data-top="transform:translatey(-50px);"
          >
            <img src={Drive} alt="drive02" />
          </span>
          <span className="banner-objects-02 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
          <span className="banner-objects-03 iq-fadebounce">
            <span className="iq-round"></span>
          </span>
        </div>
      </section>



      <div className="main-content">
        {/* trail */}
        <section
          id="pricing"
          className="overview-block-ptb grey-bg iq-price-table"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading-title">
                  <h3 className="title iq-tw-7">Affordable Price</h3>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center md:justify-center md:gap-10 md:flex md:flex-row">
              {/* trail  */}
              <div className="w-[358px] mb-10">
                <div className="iq-pricing text-center">
                  <div
                    className="price-title iq-parallax"
                    style={{ backgroundColor: "#5271FF" }}
                    data-jarallax='{"speed": 0.6}'
                  >
                    <h2 className="iq-font-white iq-tw-7">
                      <small>$</small>0
                    </h2>
                    <span className="text-uppercase iq-tw-4 iq-font-white">
                      Free Trail
                    </span>
                  </div>
                  <ul>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      10  Free Searches
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Gold-Standard Data Collection
                    </li>

                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Anti-Detection Features
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Browser-Based Automation
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Lightweight and Reliable
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Secure and Private
                    </li>
                  </ul>
                  <div className="col-md-4 col-lg-4 wow flipInY r4-mt-30"></div>
                  <div className="price-footer">
                    <NavLink
                      className="button"
                      to={"/sign-in"}
                      onClick={() =>
                        setSignInAndSignUpButtonStateToLocalStorage("signup")
                      }
                    >
                      Start Free Trial
                    </NavLink>
                  </div>
                </div>
              </div>

              {/* main pack */}
              <div className="w-[358px]">
                <div className="iq-pricing text-center">
                  <div
                    className="price-title iq-parallax"
                    style={{ backgroundColor: "#5271FF" }}
                    data-jarallax='{"speed": 0.6}'
                  >
                    <h2 className="iq-font-white iq-tw-7">
                      <small>$</small>1.25<small>/Week</small>
                    </h2>
                    <span className="text-uppercase iq-tw-4 iq-font-white">
                      Premium Plan
                    </span>
                  </div>
                  <ul>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Unlimited Searches Per Week
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Gold-Standard Data Collection
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Anti-Detection Features
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Browser-Based Automation
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Lightweight and Reliable
                    </li>
                    <li
                      style={{
                        fontSize: "16px",
                        borderBottom: "#8599ff3f solid 1px",
                      }}
                    >
                      Secure and Private
                    </li>
                  </ul>
                  <div className="col-md-4 col-lg-4 wow flipInY r4-mt-30"></div>
                  <div className="price-footer">
                    <NavLink
                      className="button"
                      to={"/sign-in"}
                      onClick={() =>
                        setSignInAndSignUpButtonStateToLocalStorage("signup")
                      }
                    >
                      Get Started
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="contact-area" id="contact">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="contact-content text-center">
            <a href="/">
              <img src={Logo} alt="logo" />
            </a>

            <h1 style={{ fontSize: "30px", color: "#fff" }}>Support</h1>
            <a
              href="mailto:support@swiftlead.io"
              style={{ fontSize: "25px", color: "#fff" }}
            >
              support@swiftlead.io
            </a>
            <br />
            <p
              style={{
                margin: "0%",
                padding: "0%",
                color: "#fff",
                fontSize: "larger",
              }}
            >
              Reach out anytime.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>)

}