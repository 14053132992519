import { useEffect, useState } from "react";
import { Select, Input, Button, message, Modal, List, Spin } from "antd";
import TextEditor from "../../utils/texteditor";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export default function BlogPage({ onUpdate }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isloadingblog, setIsLoadingblog] = useState(false);

  const handlePostBlog = async (formData) => {
    setIsLoadingblog(true)
    try {
      const response = await fetch("/api/blogs/postblogs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        message.success("Blog posted successfully!");
        onUpdate()
        setIsModalOpen(false);
      } else {
        message.error("Failed to post blog.");
      }
    } catch (error) {
      console.error("Error posting blog:", error);
      message.error("Something went wrong!");
    } finally {
      setIsLoadingblog(false)
    }
  };

  return (
    <div className="">
      <Button size="large"
        style={{ width: "126px" }} type="primary" onClick={() => setIsModalOpen(true)}>
        Add New Blog
      </Button>
      <BlogPostModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSubmit={handlePostBlog} loadingblog={isloadingblog} />
    </div>
  );
}


export function EditBlogModal({ children, blog, onUpdate }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isloadingblog, setIsLoadingblog] = useState(false);

  const handleUpdateBlog = async (formData) => {
    // console.log(formData)
    setIsLoadingblog(true)
    try {
      const response = await fetch("/api/blogs/updateblog", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ blog_uuid: blog.uuid, ...formData }), // Include blog ID
      });

      if (response.ok) {
        message.success("Blog updated successfully!");
        onUpdate()
        setIsModalOpen(false);
      } else {
        message.error("Failed to update blog.");
      }
    } catch (error) {
      console.error("Error updating blog:", error);
      message.error("Something went wrong!");
    } finally {
      setIsLoadingblog(false)
    }
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <BlogPostModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleUpdateBlog}
        initialData={blog} // Pass existing data
        loadingblog={isloadingblog}
      />
    </>
  );
}




function BlogPostModal({ isOpen, onClose, onSubmit, initialData = {}, loadingblog }) {
  const [allcat, setAllCat] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    writer_name: "",
    image: null,
    categories: [],
    isActive: true,
  });

  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({ uuid: '', name: '' });

  const [isTitleUnique, setIsTitleUnique] = useState(null); // null means not checked yet
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    if (initialData) {
      setFormData({
        title: initialData.title || "",
        description: initialData.description || "",
        writer_name: initialData.writer_name || "",
        image: initialData.image || null,
        categories: initialData?.categories?.map(item => item.value) || [],
        isActive: initialData.isActive || false,
      });
    }

  }, [initialData?.uuid, isOpen]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch("/api/blogs/categories");
      if (!response.ok) throw new Error("Failed to fetch categories");
      const result = await response.json();
      setAllCat(result.data.map((item) => ({ label: item.name, value: item.uuid })));
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Allow spaces inside, but trim leading and trailing spaces before setting state
    setFormData({ ...formData, [name]: value });

    if (name === "title" && value.trim().length > 0) {
      if (initialData?.uuid) {
        checkTitleUnique(value.trim(), initialData.uuid);
        return;
      }
      checkTitleUnique(value.trim());
    }
  };


  const handleCategoryChange = (values) => {
    if (values.length > 4) {
      message.warning("You can select up to 4 categories only!");
      return;
    }
    setFormData({ ...formData, categories: values });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFormData({
          ...formData,
          image: {
            base64: reader.result.split(",")[1], // Extract Base64
            name: file.name,
            type: file.type,
          },
        });
      };
    }
  };

  const handleNewCategorySubmit = async () => {
    if (!newCategory.trim()) {
      message.error("Category name cannot be empty");
      return;
    }

    try {
      const response = await fetch("/api/blogs/addcategory", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: newCategory }),
      });

      if (!response.ok) throw new Error("Failed to add category");

      message.success("Category added successfully!");
      setNewCategory("");
      setIsCategoryModalOpen(false);
      fetchCategories(); // Refresh categories
    } catch (error) {
      console.error("Error adding category:", error);
      message.error("Something went wrong!");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isTitleUnique === false) {
      message.error("Fill the title properly!Title Must Uniqe");
      return;
    }
    onSubmit(formData);
    setIsTitleUnique(null)
  };

  const handlerCancel = () => {
    setFormData({
      title: "",
      description: "",
      writer_name: "",
      image: null,
      categories: [],
    });
    setIsTitleUnique(null)
    onClose()
  }

  const status_Active = [
    { label: 'Active', value: true },
    { label: 'Pending', value: false },
  ]

  const handleActiveChange = (value) => {
    setFormData({ ...formData, isActive: value });
  }

  const handleEditorChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleEditCategorySubmit = async () => {
    if (!editCategory.name.trim()) return;
    console.log(editCategory)
    // onEditCategory(editCategory);
    try {
      const response = await fetch("/api/blogs/addcategory", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: editCategory.uuid,
          name: editCategory.name,
        }),
      })
      if (!response.ok) {
        message.error('Failed to update category')
        return
      }
      const data = await response.json()
      // console.log(data)
      message.success("updated successfully")
      closeAllModals()
      window.location.reload();
      // setEditCategory("");
      // setIsEditModalOpen(false);
      // fetchCategories();
    } catch (error) {
      message.error('Failed to update category', error)
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      const response = await fetch("/api/blogs/deletecategory", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ categoryId }),
      });

      const data = await response.json();

      if (!response.ok) {
        message.error(data.error || "Failed to delete category");
        return;
      }

      message.success("Category and blogs deleted successfully");

      // Refresh categories & blog list
      closeAllModals()
      window.location.reload();
    } catch (error) {
      message.error("Failed to delete category");
    }
  }
  // const handleCategoryChanges = (values) => {
  //   setSelectedCategories(values);
  // };
  const closeAllModals = () => {
    setIsEditModalOpen(false);
    setIsCategoryModalOpen(false);
    setEditCategory({ uuid: "", name: "" });
    setNewCategory("");
  };

  const checkTitleUnique = async (title, uuid = null) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({ title });
      if (uuid) queryParams.append("uuid", uuid); // Add uuid only if editing

      const response = await fetch(`/api/blogs/checktitle?${queryParams.toString()}`);
      const data = await response.json();

      setIsTitleUnique(data.isUnique);
    } catch (error) {
      console.error("Error checking title:", error);
      setIsTitleUnique(false); // Assume title is not unique on error
    }
    setLoading(false);
  };

  if (!isOpen) return null;

  return (
    <div className="mt-4 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-96   lg:w-2/3 p-8 rounded-md shadow-lg">
        {/* <div className="bg-white w-96 lg:w-2/3 px-8 rounded-md shadow-lg min-h-[600px]"> */}

        <h2 className="text-xl font-semibold mb-4 text-gray-800">{initialData?.uuid ? 'Edit' : 'Upload'} a Blog</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* <Input name="title" value={formData.title} onChange={handleChange} placeholder="Blog Title" required /> */}
          <div className="flex items-center gap-2">
            <Input
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Blog Title"
              required
            />
            {loading && <Button><Spin /></Button>}
          </div>
          {isTitleUnique === false && <p style={{ color: "red" }} className=" text-xs">❌ Title already exists</p>}
          <Input name="writer_name" value={formData.writer_name} onChange={handleChange} placeholder="Writer Name" />

          {/* Category Selection with Add Button */}
          <div className="flex items-center gap-2">
            <Select
              mode="multiple"
              maxTagCount={4}
              options={allcat}
              placeholder="Select Categories"
              value={formData.categories}
              onChange={handleCategoryChange}
              style={{ flex: 1 }}
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
              }
            />
            <Button type="primary" onClick={() => setIsCategoryModalOpen(true)}>+</Button>
          </div>


          <TextEditor content={formData.description} setContent={handleEditorChange} />

          <div className="mt-6 flex items-center gap-2">
            <Select
              options={status_Active}
              placeholder="Select Active Status"
              value={formData.isActive}
              onChange={handleActiveChange}
              style={{ flex: 1 }}
              required
            />
          </div>

          {/* <div className="mt-8"></div> */}
          <input type="file" onChange={handleImageChange} />

          <div className="flex justify-between">
            <Button type="primary" htmlType="submit" disabled={loadingblog}>Save Blog</Button>
            <Button onClick={handlerCancel}>Cancel</Button>
          </div>

        </form>
      </div>

      {/* New Category Modal */}
      <Modal
        title="Add New Category"
        open={isCategoryModalOpen}
        onCancel={() => {
          setNewCategory("");
          setIsCategoryModalOpen(false);
        }}
        onOk={handleNewCategorySubmit}
      >
        <div className="flex items-center gap-2">
          <Input
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Enter category name"
            required
          />
          <Button type="primary" onClick={() => setIsEditModalOpen(true)}>  <EditOutlined /></Button>
        </div>
      </Modal>

      {/* edit modal of categories */}

      <Modal
        title="Edit Category"
        open={isEditModalOpen}
        onCancel={() => {
          setEditCategory({ uuid: "", name: "" });
          setIsEditModalOpen(false)
        }}
        onOk={handleEditCategorySubmit}
      >

        <Input
          value={editCategory.name}
          onChange={(e) => setEditCategory(prev => {
            return {
              ...prev,
              name: e.target.value,
            }
          })}
          placeholder="Update category name"
          style={{ marginTop: "10px" }}
        />

        {/* Scrollable List */}
        <div style={{ maxHeight: "200px", overflowY: "auto", marginTop: "10px" }}>
          <List
            bordered
            dataSource={allcat}
            renderItem={(item) => (
              <List.Item
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
              >
                <span onClick={() => setEditCategory(() => {
                  return {
                    uuid: item.value,
                    name: item.label,
                  }
                })}>{item.label}</span>
                <Button type="text" danger
                  onClick={() => handleDeleteCategory(item.value)}
                >
                  <DeleteOutlined />
                </Button>
              </List.Item>
            )}
          />
        </div>

      </Modal>
    </div>
  );
}

