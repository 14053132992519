import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: ["red", "blue", "green", "orange", "black"] }],
    [{ background: ["yellow", "cyan", "pink", "white"] }], // Text color & Background color
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"], // Remove formatting
  ],
};

const TextEditor = ({ content, setContent }) => {


  return (
    <div className="h-[180px] overflow-y-auto border border-gray-300 rounded-md">
      <ReactQuill
        value={content}
        onChange={setContent}
        modules={modules}
        theme="snow"
        style={{ height: "180px" }} // Ensure editor stays inside fixed height
      />
    </div>
  );
};


export default TextEditor;
