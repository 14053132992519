import Chrome from "../../assets/services/icon1.png";
import Plan from "../../assets/services/icon2.png";
import Rocket from "../../assets/services/icon3.png";
import { NavLink } from "react-router-dom";

export default function Works({ youtube_url, onclick }) {
  return (
    <div className="main-content">
      <section
        id="how-it-works"
        className="overview-block-ptb it-works bg-white"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading-title">
                <h3 className="title iq-tw-7">3 Simple Steps To Launch </h3>
                <p>
                  Getting started with swiftlead is a simple, fast process,
                  unlocking unmatched
                  <br />
                  productivity and efficiency has never been easier.
                </p>
                <div>
                  <button onClick={() => window.open(youtube_url, '_blank')}
                    className="button button-gradient">How to Start Free Trial</button>
                  <button
                    onClick={() => window.open('https://youtu.be/0VPPpbkK0XY?si=Oxy8nEPi2SrgbkGp', '_blank')}
                    className="button button-glass">Watch Tutorial</button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="iq-works-box text-left">
                <div className="step">1</div>
                <div className="icon-bg text-center">
                  <img src={Chrome} className="img-fluid" alt="Chrome" />
                </div>
                <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">
                  Chrome Extension
                </h5>
                <p>Download our chrome extension.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 r-mt-30">
              <div className="iq-works-box text-left">
                <div className="step">2</div>
                <div className="icon-bg text-center">
                  <img src={Plan} className="img-fluid" alt="Plan" />
                </div>
                <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">
                  Plan
                </h5>
                <p>Sign up for our plan.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 r-mt-30">
              <div className="iq-works-box text-left">
                <div className="step">3</div>
                <div className="icon-bg text-center">
                  <img src={Rocket} className="img-fluid" alt="Rocket" />
                </div>
                <h5 className="iq-tw-7 text-uppercase iq-mt-25 iq-mb-15">
                  Enjoy
                </h5>
                <p>Let swiftlead work its magic.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  )
}